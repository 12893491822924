<template>
  <div class="ps-page--my-account">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img src="/img/logo.png" alt="Pahukama" max-width="100%" height="auto" />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'merchant_register' }">Merchant</router-link>
        </div>
      </div>
    </div>
    <div class="ps-my-account">
      <div class="ps-container">
        <form class="ps-form--account ps-tab-root">
          <ul class="ps-tab-list">
            <li class="active">
              <a href="#sign-in">Forgot Password</a>
            </li>
          </ul>
          <div class="ps-tabs">
            <div class="ps-tab active" id="sign-in">
              <div class="ps-form__content">
                <h5>Send Password Reset Link</h5>
                <div v-if="errors.message" class="alert alert-danger" role="alert">
                  {{ errors.message }}
                </div>
                <div class="form-group">
                  <input class="form-control" v-model="user.email" type="email" placeholder="Email address" />
                  <small v-if="errors.email" class="mobile-number-error text-danger">{{ errors.email[0] }}</small>
                </div>
                <div class="form-group submit">
                  <button class="ps-btn ps-btn--fullwidth login" :disabled="loading" @click.prevent="onSubmit()">
                    {{ loading ? "" : "Send Link" }}
                    <i v-if="!loading" class="icon icon-right-arrow ml-1"></i>
                    <div v-if="loading" class="spinner-border text-light" role="status"></div>
                  </button>
                </div>
              </div>
              <div class="ps-form__footer">
                <router-link :to="{ name: 'login' }">Back to Login
                </router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AuthFooter from "./AuthFooter";
import axios from "axios";

export default {
  name: "ForgotPassword",
  components: { AuthFooter },
  data() {
    return {
      user: {
        email: "",
        verification_code: "",
      },
      errors: {
        email: [],
        message: "",
      },
      loading: false,
      verify: false,
    };
  },

  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    onSubmit() {
      this.loading = true;
      let data = { email: this.user.email };
      axios
        .post("forgot/password", data)
        .then((response) => {
          if (response.data.status == 'passwords.sent') {
            this.$toasted.show("Reset Password has been sent to your email.");

            this.user.email = "";
            return this.loading = false;
          }
          if (response.data.status === false) {
            this.$toasted.show(response.data.message);
            this.errors.message = response.data.message;
            return this.loading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.message = "Please enter all required fields.";
            this.errors = error.response.data.errors;
          } else if (error.response.status === 401) {
            this.errors.message = error.response.data.message;
          } else {
            this.message = error.backendErrors[0];
          }
          this.isError = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
